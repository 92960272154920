::-moz-selection {
    background: rgba(255, 255, 255, 0.4);
}
::selection {
    background: rgba(255, 255, 255, 0.4);
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-family: "Raleway", sans-serif;
    background: black;
    color: white;
    font-size: 16px;
    font-weight: 300;
}
body {
    font-size: 15px;
}
strong {
    font-weight: bold;
}
a {
    color: inherit;
    text-decoration: none;
}

.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    position: fixed;
    color: black;
    width: 300px;
    height: 100%;
    padding: 0 3rem;
    overflow-y: auto;
}
.sidebar__header {
    display: flex;
    flex-direction: column;
}
.sidebar__logo {
    margin: 3rem 0;
    width: 10.4rem;
}
.sidebar__menu {
    list-style: none;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-transform: uppercase;
}
.sidebar__divider {
    margin: 2rem 0;
    border: none;
    font-size: 1.5rem;
    width: 0.75rem;
    border-bottom: 1px solid black;
}
.sidebar__menu_small {
    font-size: 1.25rem;
    text-transform: none;
}
.sidebar__menu a {
    text-decoration: none;
}
.sidebar__link_active {
    font-weight: bold;
}
.sidebar__footer {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    padding: 3rem 0;
}
.sidebar__footer p {
    margin: 0.25rem 0;
}
.sidebar__footer b {
    font-weight: bold;
}
.sidebar__footer b::after {
    content: '_';
    font-weight: normal;
}
@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        min-height: 100%;
        padding: 0 2.5rem;
        position: static;
    }
    .sidebar__logo {
        margin: 2rem 0;
    }
    .sidebar__menu {
        float: left;
        width: 100%;
        font-size: 1.5rem;
        line-height: 2rem;
        text-transform: uppercase;
    }
    .sidebar__menu li {
        float: left;
        width: 100%;
    }
    .sidebar__menu:last-of-type {
        margin-bottom: 2rem;
    }
    .sidebar__divider {
        display: none;
    }
    .sidebar__menu a {
        float: left;
        width: 100%;
    }
    .sidebar__footer {
        padding: 1.5rem 0;
    }
    .sidebar__footer_no-mobile {
        display: none;
    }
}

.references {
    list-style: none;
    margin: -16px;
    float: left;
}
.references li {
    float: left;
    padding: 0 16px 16px 16px;
}
.references img {
    float: left;
    width: 100px;
}

#header {
    background: no-repeat right center;
    background-size: cover;
}
#header blockquote {
    color: white;
    font-size: 30px;
    font-weight: 300;
    line-height: 1em;
    align-self: flex-end;
}
#header blockquote:before {
    content: '';
    display: block;
    background: url('../img/quotes.png')  no-repeat top left;
    background-size: contain;
    width: 32px;
    height: 32px;
    margin-bottom: 32px;
}
#header blockquote h1 {
    font-size: 1.6em;
    font-weight: inherit;
    margin: 0.4em 0;
    text-transform: uppercase;
    line-height: 1em;
}
@media (min-width: 1400px) {
    #header blockquote.large {
        font-size: 44px;
        align-self: flex-start;
    }
}

#header .slider,
#header .slick-list,
#header .slick-track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.slick-slide > div, .slick-slide > div > div {
  height: 100%;
  background: no-repeat center;
  background-size: cover;
  outline: none;
}
.slick-dots {
    position: absolute;
    z-index: 1;
    margin: 0;
    padding: 0;
    top: 2rem;
    left: 2.5rem;
}
.slick-dots li {
    float: left;
    list-style: none;
}
.slick-dots button {
    position: relative;
    float: left;
    width: 15px;
    height: 15px;
    background: none;
    border: none;
    color: transparent;
}
.slick-dots button:focus {
    outline: none;
}
.slick-dots button::after {
    content: "";
    position: absolute;
    top: 7px;
    bottom: 7px;
    left: 3px;
    right: 3px;
    background: white;
    transition: 0.2s;
}
.slick-dots .slick-active button::after {
    background: black;
}
.slick-dots button:hover::after {
    top: 3px;
    bottom: 3px;
}

.home_main {
    position: absolute;
    left: 300px;
    right: 0;
    bottom: 0;
    top: 0;
}
.home_main .slider,
.home_main .slick-list,
.home_main .slick-track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.home_main .slider .slick-slide {
    height: 100%;
    background: no-repeat center;
    background-size: cover;
    outline: none;
}
@media (max-width: 768px) {
    .home_main {
        position: relative;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        float: left;
        height: 400px;
        width: 100%;
    }
}

#main p {
    margin: 20px 0;
}
#main h1, #main h2 {
    margin: 30px 0 23px 0;
    font-size: 23px;
}
#main h3 {
    margin: 1em 0;
    font-size: 15px;
}
#main .col {
    margin-bottom: 30px;
}
#main *:first-child {
    margin-top: 0;
}
#main *:last-child {
    margin-bottom: 0;
}

#projects {
    list-style: none;
    text-align: right;
}
#projects li {
    position: relative;
    float: left;
    width: 100%;
}
#projects li:not(:last-child) {
    margin-bottom: 2.5rem;
}
#projects a {
    float: left;
    width: 100%;
}
#projects h2 {
    color: black;
    position: absolute;
    top: -0.15em;
    right: 0;
    margin: 0;
    text-transform: uppercase;
    font-size: 26px;
    line-height: 1em;
}
.project #header {
    background: no-repeat center;
    background-size: cover;
}
.project #header h1 {
    line-height: 1em;
    color: white;
    text-transform: uppercase;
}
#projects small {
    font-size: 0.5em;
}
#projects img {
    width: 100%;
    float: left;
}
.project #header small {
    font-size: 0.5em;
}
.showroom {
    list-style: none;
}

.showroom img {
    width: 100%;
    float: left;
}

.showroom li {
    position: relative;
    float: left;
    width: 100%
}

.showroom li:not(:last-child) {
    margin-bottom: 2.5rem
}